<template>
  <div>
    <div
      v-if="pcImport.status == 'unstarted'"
    >
      <b-button
        variant="primary"
        @click="startImport"
      >Start import</b-button>
    </div>
    <div
      v-else-if="pcImport.status == 'importing'"
    >
      <h4>Import in progress ...</h4>
      <p>
        {{ pcImport.percent_complete }}% complete
        <b-spinner class="align-middle"></b-spinner>
      </p>
    </div>
    <div
      v-else-if="pcImport.status == 'completed'"
    >
      <h4>Import complete</h4>
      <p>
        Completed at {{ pcImport.completed_at }}.        
      </p>
      <p>
        <b-button
          variant="primary"
          @click="startImport"
        >Start another import</b-button>
      </p>
    </div>
    <p>
      
    </p>    
  </div>
</template>

<script>
export default ({
  name: 'PcImport',
  data() {
    return {
      pcImport: {}
    }
  },
  created() {
    this.updateImport()
  },
  methods: {
    startImport() {
      this.secured.post('/pc_import/start')
        .then(response => {
          const uuid = response.data.uuid
          const clientId = response.data.client_id
          const redirectUri = response.data.redirect_uri
          const scopes = response.data.scopes
          const state = {
            uuid: uuid,
            return_url: window.location.href
          }
          const encodedState = window.btoa(JSON.stringify(state))

          const oauth_url =
           `https://api.planningcenteronline.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}&state=${encodedState}`

           window.location.href = oauth_url
        })
        .catch(error => console.log(error)) 
    },
    updateImport() {
      this.secured.post('/pc_import/for_user')
        .then(response => {
          this.pcImport = response.data.pc_import
          if(this.pcImport.status == "importing") {
            setTimeout(() => this.updateImport(), 2000)
          }
        })
        .catch(error => console.log(error))
    }
  }
})
</script>