<template>
  <div>
    <b-card>
      <h4>Welcome to Congregational Intelligence</h4>
      <p>Revolutionize the way you understand your congregation's data with plain language searches.</p>
      <p>
        <b-button to="report-generator" variant="primary">Get Started</b-button>
      </p>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  created() {
    if(!localStorage.signedIn){
      this.$router.replace('/signin')
    }
  }
}
</script>
