<template>
  <div>
    <div>
      <b-navbar toggleable="lg" type="light" style="background-color: #fdf7f2 !important;">
        <b-navbar-brand to="/" class="mx-2"><b-img src="logo.png" fluid width="50px;"></b-img></b-navbar-brand>            
        <b-navbar-toggle target="nav-collapse" class="mx-2"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item class="mx-2" to="/">Home</b-nav-item>
            <b-nav-item class="mx-2" to="/report-generator">Assistant</b-nav-item>
            <!-- <b-nav-item to="/pc-import">Import from Planning Center</b-nav-item> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    
    <main class="p-3 vh-90" style="background-color: #e8e8e8">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>
