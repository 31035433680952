import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/components/HomePage'
import SignIn from '@/components/SignIn'
import ReportGenerator from '@/components/ReportGenerator'
import PcImport from '@/components/PcImport'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/report-generator',
      name: 'ReportGenerator',
      component: ReportGenerator
    },
    {
      path: '/signin',
      name: 'SignIn',
      component: SignIn
    },
    {
      path: '/pc-import',
      name: 'PcImport',
      component: PcImport
    }
  ]
})