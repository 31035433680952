<template>
  <div>
    <b-card class="mb-4" title="Welcome">
      <p style="max-width:800px">
        Hello {{ userDisplayName }}, thanks for trying out this demo. This sample database has information on 
        people, groups, group membership, and giving (donation) history. Ask me anything! If it's in the data,
        I'll do my best to get you the right answers.
      </p>

      <div v-if="!loadingSuggestions && currentSuggestion">
        <p>
          If you're not sure where to start, try one of these suggestions:
        </p>
        <b-container>
          <b-row class="promt-suggestion-row">
            <b-col class="prompt-suggestion-chevron">
              <b-link @click="changeSuggestion(-1)">
                <b-icon-chevron-compact-left></b-icon-chevron-compact-left>
              </b-link>
            </b-col>
            <b-col cols="8">
              <p class="prompt-suggestion">
                <transition name="slide-fade" mode="out-in">
                  <div
                    :key="currentSuggestion"
                    class="mb-2"
                  >
                    {{ currentSuggestion }}
                  </div>
                </transition>
              </p>
              <div class="mx-auto" style="width:130px">
                <b-button
                  size="sm"
                  variant="success"
                  @click="submitSuggestion(currentSuggestion)"
                >
                Try Suggestion
                </b-button>
              </div>
            </b-col>
            <b-col class="prompt-suggestion-chevron">
              <b-link @click="changeSuggestion(1)">
                <b-icon-chevron-compact-right></b-icon-chevron-compact-right>
              </b-link>
            </b-col>
          </b-row>
        </b-container>
    </div>
    </b-card>
    <b-form-textarea
      id="textarea"
      v-model="message"
      placeholder="What can I do for you?"
      rows="3"
      max-rows="6"
      @focus="$event.target.select()"
      @keydown.enter.prevent="generateReport()"
    ></b-form-textarea>

    <div>
      <b-button
        variant="success"
        class="mt-3 mb-3"
        :disabled="responseLoading || message.length < 10"
        @click="generateReport()"
      >Submit <b-spinner v-if="responseLoading" small class="ml-5 align-middle"></b-spinner>
      </b-button>
      <span v-if="responseHistory.length>1">
        &nbsp;
        <b-button
          :disabled="responseLoading || historyIndex <= 0"
          variant="outline-primary"
          @click="setHistoricalResponse(-1)"
        >
          <b-icon-chevron-compact-left></b-icon-chevron-compact-left>
        </b-button>
        <span class="mx-2"></span>
        <b-button
          :disabled="responseLoading || historyIndex === responseHistory.length-1"
          variant="outline-primary"
          @click="setHistoricalResponse(1)"
        >
          <b-icon-chevron-compact-right></b-icon-chevron-compact-right>
        </b-button>
      </span>
    </div>

    <b-card ref="responseCardRef" style="min-height:77vh">
      <div v-if="errorMessage || responseExplanation">
        {{ errorMessage ? errorMessage : responseExplanation}}
      </div>

      <div v-if="reportData.length > 0">
        <div>
          <b-input v-model="itemsPerPage" style="max-width:60px; display:inline"></b-input>
          results per page
        </div>

        <b-pagination
          v-if="reportData.length > itemsPerPage"
          v-model="currentPage"
          :total-rows="reportData.length"
          :per-page="itemsPerPage"
          aria-controls="reportTable"
          class="mt-3"
        ></b-pagination>

        <p>{{ reportData.length }} total result(s)</p>

        <b-button
          v-b-tooltip.hover title="Download results as CSV"
          @click="downloadCsv"
        >
          <b-icon-download></b-icon-download>
        </b-button>

        <b-button
          v-if="reportDataHasEmails"
          class="mx-4"
          v-b-tooltip.hover title="Copy email addresses to the clipboard"
          @click="copyEmails"
        >
          <b-icon-envelope></b-icon-envelope>
        </b-button>
        <b-alert
          :show="emailsCopiedDismissCountDown"
          fade
          variant="warning"
          class="mt-3"
          @dismiss-count-down="emailsCopiedCountDownChanged"
        >
          Emails copied to clipboard
        </b-alert>
        <b-table
          id="reportTable"
          striped
          hover
          sticky-header="55vh"
          :responsive="true"
          :per-page="itemsPerPage"
          :current-page="currentPage"
          :items="reportData"
          :fields="reportFields"
          label-sort-asc=""
          label-sort-desc=""
          class="mt-3"
        >
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
export default ({
  name: 'ReportGenerator',
  data() {
    return {
      databases: [],
      selectedDatabaseId: "",
      message: "",
      errorMessage: "",
      responseExplanation: "",
      reportData: [],
      responseLoading: false,
      itemsPerPage: 100,
      currentPage: 1,
      suggestions: [],
      currentSuggestionIndex: 0,
      suggestionCycleSeconds: 4,
      suggestionTimeout: null,
      userDisplayName: '',
      loadingSuggestions: true,
      emailsCopiedDismissCountDown: 0,
      responseHistory: [],
      historyIndex: -1
    }
  },
  created() {
    if(!localStorage.signedIn){
      this.$router.replace('/signin')
    } else{
      this.userDisplayName = localStorage.userDisplayName
      this.fetchDatabases()
    }
  },
  computed: {
    reportFields() {
      if (this.reportData.length){
        return Object.keys(this.reportData[0]).map((key) => {
          return { key: key, sortable: true }
        })
      } else {
        return []
      }      
    },
    reportDataHasEmails() {
      return this.reportData.length > 0 && "email" in this.reportData[0]
    },
    currentSuggestion() {
      if(!this.loadingSuggestions && this.suggestions.length){
        return this.suggestions[this.currentSuggestionIndex]
      } else{
        return ""
      }      
    }
  },
  methods: {
    fetchDatabases() {
      this.secured.post('/databases')
        .then(response => {
          this.databases = response.data
          this.selectedDatabaseId = this.databases[0].value
          this.fetchSuggestions()
        })
        .catch(error => console.log(error))      
    },
    fetchSuggestions(){
      this.loadingSuggestions = true
      const data = {
        db_id: this.selectedDatabaseId
      }

      this.secured.post('/db_chat/suggested_prompts', data)
        .then(response => {
          if(response.data.length > 0) {
            this.suggestions = response.data
            this.changeSuggestion(1)
          } else {
            console.log("There was an error fetching new suggestions.")
          }
          this.loadingSuggestions = false
        })
    },
    changeSuggestion(value) {
      if(this.suggestionTimeout)
      {
        clearTimeout(this.suggestionTimeout)
      }

      let newIndex = this.currentSuggestionIndex + value
      if(newIndex < 0) {
        newIndex = this.suggestions.length-1
      } else {
        newIndex %= this.suggestions.length
      }
      
      this.currentSuggestionIndex = newIndex

      this.suggestionTimeout = setTimeout(() => this.changeSuggestion(1), this.suggestionCycleSeconds * 1000)
    },
    clearResponse(){
      this.errorMessage = ''
      this.responseExplanation = ''
      this.reportData = []
      this.currentPage = 1
      this.historyIndex = -1
    },
    generateReport(){
      this.responseLoading = true
      this.clearResponse()

      const requestData = {
        db_id: this.selectedDatabaseId,
        message: this.message,
      }

      this.secured.post('/db_chat/query', requestData)
        .then(response =>{
          const data = response.data
          const newHistoryObj = {
            ...data,
            message: this.message
          }
          this.responseHistory.push(newHistoryObj)
          this.historyIndex = this.responseHistory.length-1

          this.setResponse(data)
          this.responseLoading = false
        })
        .catch(error => {
          this.responseLoading = false
          console.log(error)
        })
    },
    setResponse(data){
      this.errorMessage = ''
      this.responseExplanation = ''
      this.reportData = []
      this.currentPage = 1

      if(data.error_message){
        this.errorMessage = data.error_message
      }
      else if(data.results_explanation) {
        this.responseExplanation = data.results_explanation
      }
      else if(data.results_data.length > 0){
        this.reportData = data.results_data
      }
      else{
        this.errorMessage = "I had some trouble fulfilling your request. Please try again or rephrase."
      }
    },
    submitSuggestion(suggestion){
      this.$refs.responseCardRef.scrollIntoView({ behavior: 'smooth' })
      this.message = suggestion
      this.generateReport()
    },
    setHistoricalResponse(incrementVal){
      this.historyIndex += incrementVal
      if(this.historyIndex < 0 || this.historyIndex >= this.responseHistory.length){
        this.historyIndex = -1
        return
      }

      let historyObj = this.responseHistory[this.historyIndex]
      this.message = historyObj.message
      this.setResponse(historyObj)
    },
    downloadCsv() {
      if(this.reportData.length > 0){
        const data = this.reportData
        const headers = Object.keys(data[0])

        const csv = [
          headers.join(","), 
          ...data.map((obj) => headers.map((key) => obj[key]).join(",")), 
        ].join("\n");

        const filename = "ci_report.csv"

        const link = document.createElement("a")
        link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv))
        link.setAttribute("download", filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    copyEmails() {
      const emailList = this.reportData.map((obj) => obj.email).join(',')
      navigator.clipboard.writeText(emailList).then(() => {
        this.emailsCopiedDismissCountDown = 3
      }, function() {
        console.error("Unable to copy text to clipboard.")
      });
    },
    emailsCopiedCountDownChanged(newCountDown){
      this.emailsCopiedDismissCountDown = newCountDown
    }
  }
})
</script>

<style scoped>
  .promt-suggestion-row {
    max-width: 400px;
  }
  .prompt-suggestion {
    font-style: italic;
    color: black;
    height: 75px;
    text-align: center;
  }

  .prompt-suggestion-chevron {
    line-height: 50px;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for <2.1.8 */ {
    /* transform: translateX(10px); */
    opacity: 0;
  }
</style>